<template>
  <v-layout justify-center align-center v-if="authUser && authUser.id">
    <v-flex md8 lg6>
      <form @submit.prevent="submit()">
        <v-card>
          <v-card-title>
            <div class="headline">
              {{
                `${
                  authUser.mobile ? $lang("UpdateMobile") : $lang("AddMobile")
                }`
              }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="authUser && !authUser.mobile_verified"
              outlined
              text
              @click="$router.push({ name: 'verify-mobile' })"
              >{{ $lang("GotOTP") }}</v-btn
            >
          </v-card-title>
          <v-divider v-if="authUser.mobile"></v-divider>
          <v-card-actions v-if="authUser.mobile">
            {{ $lang("Current") + " " + $lang("Mobile") }}
            <v-spacer></v-spacer>
            {{ authUser.mobile }}
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              prepend-icon="mdi-phone"
              :label="$lang('MobileNumber')"
              :hint="$lang('Your mobile number.')"
              v-model="user.mobile"
              type="number"
              :error-messages="errors && errors.mobile ? errors.mobile : []"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-lock"
              :label="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-LABEL')"
              :hint="$lang('AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-HINT')"
              v-model="user.password"
              type="password"
              :error-messages="errors && errors.password ? errors.password : []"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined type="submit">{{ $lang("SendOTP") }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="$router.push({ name: 'profile' })">{{
              $lang("Cancel")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "update-mobile",
  data() {
    return {
      user: { mobile: null, password: null },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    authUser() {
      this.check();
    },
  },
  mounted() {
    this.check();
  },
  methods: {
    check() {
      if (this.authUser && !this.authUser.mobile_verified) {
        if (this.authUser.mobile || this.authUser.temp_mobile) {
          console.log("has unverified mobile");
          this.$router.replace({ name: "verify-mobile" });
          return;
          /*
          this.$store.dispatch(
            "setAlert",
            {
              type: "error",
              message:
                "You have added a number previously, you may verify it first",
            },
            { root: true }
          );
          */
        }
      }
      if (this.authUser) {
        this.user.mobile = this.authUser.temp_mobile
          ? this.authUser.temp_mobile
          : this.authUser.mobile;
      }
    },
    ...mapActions({
      updateMobile: "auth/updateMobile",
    }),
    submit() {
      this.updateMobile({ user: this.user, context: this })
        .then((res) => {
          return this.$store.dispatch("auth/fetchUser").finally(() => {
            this.$router.replace({ name: "verify-mobile" });
          });
        })
        .catch((err) => {});
    },
  },
};
</script>


